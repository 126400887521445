import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Progress } from 'antd';
import { selectIsShowLoadingBar } from '../state/loadingBarSelectors';
import { StyledLoadingBar } from './Loadingbar.styles';

export const LoadingBarComponent = (): JSX.Element => {
  const isLoading = useSelector(selectIsShowLoadingBar);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (isLoading === true) {
      setProgress(1);
    }
    if (isLoading === false) {
      setProgress(99);
    }
  }, [isLoading]);

  useEffect(() => {
    let intervalId: string | number | NodeJS.Timeout | undefined;

    if (progress > 0 && progress < 100) {
      intervalId = setInterval(() => {
        setProgress(prevProgress => {
          const newProgress = prevProgress + 4;
          if (newProgress >= 100) {
            clearInterval(intervalId); // Stop the interval when progress reaches 100
            return 100; // Ensure it's exactly 100
          }
          return newProgress;
        });
      }, 400); // Adjust the interval for speed (lower is faster)
    }

    return () => clearInterval(intervalId); // Cleanup on unmount or re-run
  }, [progress]);

  const isShow = progress > 0 && progress < 100;

  return (
    <StyledLoadingBar show={isShow} className="loading-bar">
      <Progress
        strokeColor={'white'}
        trailColor={'#9A9A9A'}
        size={20}
        type="circle"
        percent={progress}
        format={(): string => ''}
      />
      <span className="loading-bar__update-text">Updating data</span>
    </StyledLoadingBar>
  );
};
