import styled, { Keyframes, keyframes } from 'styled-components';

interface LoadingBarComponentProps {
  className?: string;
  show?: boolean;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const StyledLoadingBar = styled.div<LoadingBarComponentProps>`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  zindex: 9999;
  background-color: #6e6e6e;
  padding: 9px 12px;
  border-radius: 8px;
  box-shadow: 0px 9px 28px 8px #0000000d;
  box-shadow: 0px 3px 6px -4px #0000001f;
  box-shadow: 0px 6px 16px 0px #00000014;
  display: ${(props): string => (props.show ? 'block' : 'none')};
  animation: ${(props): Keyframes => (props.show ? fadeIn : fadeOut)} 1s ease-in-out;

  .loading-bar__update-text {
    color: white;
    font-size: 14px;
    font-weight: 300;
    margin-left: 10px;
    font-family: 'ClanWebPro-News';
    text-align: center;
    vertical-align: middle;
  }
`;
