export const LANGUAGES_USER_SNAP_SUPPORTED: Record<string, string> = {
  en: 'en',
  cs: 'cs',
  de: 'de',
  es: 'es',
  fr: 'fr',
  hr: 'hr',
  hu: 'hu',
  it: 'it',
  ja: 'ja',
  ko: 'ko',
  lt: 'lt',
  nl: 'nl',
  pl: 'pl',
  ro: 'ro',
  ru: 'ru',
  sk: 'sk',
  sv: 'sv',
  tr: 'tr',
  pt: 'pt',
  zh: 'zh-CN',
};

export const BackendErrorMapping: Record<string, string> = {
  'BackendError.INVALID_REPORT_TYPE': 'toasts.backendError.invalidReportType',
  'BackendError.WORK_INTERVAL_NOT_FOUND': 'toasts.backendError.workIntervalNotFound',
  'BackendError.CLEANING_REPORT_EXPORT_REQUEST_NOT_FOUND': 'toasts.backendError.cleaningReportExportRequestNotFound',
  'BackendError.MACHINE_NOT_FOUND': 'toasts.backendError.machineNotFound',
  'BackendError.CLEANING_TASK_REPORT_NOT_FOUND': 'toasts.backendError.cleaningTaskReportNotFound',
  'BackendError.REPORT_DOES_NOT_HAVE_MAP_ID': 'toasts.backendError.reportDoesNotHaveMapId',
  'BackendError.MAP_NOT_FOUND_FOR_CLEANING_ROUTE': 'toasts.backendError.mapNotFoundForCleaningRoute',
  'BackendError.UNKNOWN_MAP_ERROR': 'toasts.backendError.unknownMapError',
  'BackendError.ACTUAL_OR_PLANNED_OPERATING_TIME_NOT_FOUND': 'toasts.backendError.actualOrPlannedOperatingTimeNotFound',
  'BackendError.INVALLID_ARGUMENT_WEEKDAY_NOT_INTEGER': 'toasts.backendError.invalidArgumentWeekdayNotInteger',
  'BackendError.INVALLID_ARGUMENT_HOURS_NOT_INTEGER': 'toasts.backendError.invalidArgumentHoursNotInteger',
  'BackendError.INVALLID_ARGUMENT_MINUTES_NOT_INTEGER': 'toasts.backendError.invalidArgumentMinutesNotInteger',
  'BackendError.SITE_NOT_FOUND': 'toasts.backendError.siteNotFound',
  'BackendError.CUSTOMER_NOT_FOUND': 'toasts.backendError.customerNotFound',
  'BackendError.USER_NOT_FOUND': 'toasts.backendError.userNotFound',
  'BackendError.USER_HAS_NOT_REGISTERED_PUDS_CANNOT_UPDATE_EMAIL':
    'toasts.backendError.userHasNotRegisteredPudsCannotUpdateEmail',
  'BackendError.USER_STATUS_IS_NOT_VALID_CANNOT_UPDATE_USER_STATUS':
    'toasts.backendError.userStatusIsNotValidCannotUpdateUserStatus',
  'BackendError.USER_ALREADY_EXISTS_WITH_SAME_EMAIL': 'toasts.backendError.userAlreadyExistsWithSameEmail',
  'BackendError.THIS_EMAIL_HAS_NOT_BEEN_INVITED_YET': 'toasts.backendError.thisEmailHasNotBeenInvitedYet',
  'BackendError.THIS_EMAIL_HAS_ALREADY_BEEN_REGISTERED': 'toasts.backendError.thisEmailHasAlreadyBeenRegistered',
  'BackendError.FIRST_NAME_IS_REQUIRED': 'toasts.backendError.firstNameIsRequired',
  'BackendError.LAST_NAME_IS_REQUIRED': 'toasts.backendError.lastNameIsRequired',
  'BackendError.INVALID_ALG_NUMBER': 'toasts.backendError.invalidAlgNumber',
  'BackendError.KEYWORD_REQUIRED': 'toasts.backendError.keywordRequired',
  'BackendError.GEOFENCE_NOT_FOUND': 'toasts.backendError.geofenceNotFound',
};
