import { eventChannel, EventChannel } from 'redux-saga';
import { Observable } from 'zen-observable-ts';

type NotUndefined = object | null;
export class SagaUtils {
  public static createChannelFromObservable = <T extends NotUndefined>(observable: Observable<T>): EventChannel<T> =>
    eventChannel<T>(emit => {
      const subscription = observable.subscribe({
        next: event => {
          emit(event);
        },
        error(errorValue: unknown) {
          console.error('Error while processing message in observable channel:', errorValue);
        },
      });

      return () => {
        subscription.unsubscribe();
      };
    });
}
