import { createSlice } from '@reduxjs/toolkit';

export interface ILoadingBarState {
  isShow: boolean | undefined;
}

export const initialState: ILoadingBarState = {
  isShow: undefined,
};

const loadingBarSlice = createSlice({
  name: 'loading-bar',
  initialState,
  reducers: {
    showLoadingBar: state => {
      state.isShow = true;

      return state;
    },
    hideLoadingBar: state => {
      state.isShow = false;

      return state;
    },
  },
});

export const LoadingBarActions = loadingBarSlice.actions;
export const LoadingBarReducer = loadingBarSlice.reducer;
